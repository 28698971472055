import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
  container: {
    borderWidth: 1,
    borderColor: mode('zircuitPrimary', 'zircuitLight')(props),
    bg: mode('greenOnBeige', 'darkGreenOnBeige')(props),
    color: mode('zircuitPrimary', 'zircuitLight')(props),
    fontSize: '0.625rem',
    lineHeight: '0.625rem',
    py: 0,
    px: 1.5,
    minH: '1.25rem',
    maxH: '1.25rem',
  },
}))

export const Tag = defineMultiStyleConfig({
  baseStyle,
})
