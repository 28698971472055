import { readContract } from '@wagmi/core'
import { ethers } from 'ethers'
import { keyBy } from 'lodash'
import React from 'react'
import { erc20Abi } from 'viem'
import type { Address } from 'viem'
import { useAccount, useBalance } from 'wagmi'
import { useErc20TokensQuery } from 'apollo/generated/graphqlClient'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { DECIMALS, DEFAULT_VALUE } from 'constants/common'
import { CHAINS, ETH } from 'constants/network'
import { OTFS } from 'constants/otfs'
import { wagmiConfig } from 'lib/wagmi'
import { useFeatureFlagsContext } from 'providers/FeatureFlagsProvider'
import type { EthAsset } from 'types/network'

export type Asset = Erc20Token | EthAsset
export type AssetWithBalance = { value: string } & Asset

export const useAssets = (): {
  assets: Asset[]
  assetsWithBalance: AssetWithBalance[]
  areAssetsWithBalanceLoading: boolean
  refetchAssetsWithBalance: () => Promise<void>
} => {
  const { isOTFEnabled } = useFeatureFlagsContext()
  const { address, chain: activeChain } = useAccount()
  const [assetsBalances, setAssetsBalances] = React.useState<Record<string, AssetWithBalance>>({})
  const [areErc20TokensLoading, setAreErc20TokensLoading] = React.useState<boolean>(true)

  const {
    data: ethBalanceData,
    isLoading: isEthBalanceLoading,
    refetch: refetchEthBalance,
  } = useBalance({
    address,
  })
  const { data: { erc20Tokens = [] } = {} } = useErc20TokensQuery()

  const isL1NetworkSelected = activeChain?.id === CHAINS.l1.id

  const OTF_ASSETS = React.useMemo(
    () =>
      isOTFEnabled
        ? OTFS.map((otf, index) => ({
            id: 100000 + index,
            name: otf.name,
            symbol: otf.symbol,
            image: otf.symbol,
            abi_l1: '',
            abi_l2: '',
            contract_address_l1: otf.contract_address_l1,
            contract_address_l2: otf.contract_address_l2,
            decimals_l1: otf.decimals_l1,
            decimals_l2: otf.decimals_l2,
          }))
        : [],
    [isOTFEnabled],
  )

  const tokensToFetch = React.useMemo(
    () => [...erc20Tokens, ...OTF_ASSETS],
    [erc20Tokens, OTF_ASSETS],
  )

  const handleERC20TokensBalance = React.useCallback(async () => {
    setAreErc20TokensLoading(true)

    const tokensWithBalances = await Promise.all(
      tokensToFetch.map(async (token: Erc20Token) => {
        const contractAddress = isL1NetworkSelected
          ? token.contract_address_l1
          : token.contract_address_l2

        const balance = await readContract(wagmiConfig, {
          abi: erc20Abi,
          address: contractAddress as Address,
          functionName: 'balanceOf',
          args: [address!],
        })

        const decimals = isL1NetworkSelected ? token.decimals_l1 : token.decimals_l2

        return {
          ...token,
          value: ethers.formatUnits(balance, decimals).toString(),
        }
      }),
    )

    setAssetsBalances((prev) => ({
      ...prev,
      ...keyBy(tokensWithBalances, 'symbol'),
    }))

    setAreErc20TokensLoading(false)
  }, [isL1NetworkSelected, address, tokensToFetch])

  React.useEffect(() => {
    if (!isEthBalanceLoading) {
      setAssetsBalances((prev) => ({
        ...prev,
        [ETH.symbol]: {
          ...ETH,
          value: ethers
            .formatUnits(
              ethBalanceData?.value ?? DEFAULT_VALUE,
              ethBalanceData?.decimals ?? DECIMALS,
            )
            .toString(),
        },
      }))
    }
  }, [ethBalanceData, isEthBalanceLoading])

  React.useEffect(() => {
    if (tokensToFetch.length > 0 && address) {
      void handleERC20TokensBalance()
    } else {
      setAreErc20TokensLoading(false)
    }
  }, [address, handleERC20TokensBalance, isL1NetworkSelected, tokensToFetch.length])

  return {
    assets: [ETH, ...erc20Tokens, ...OTF_ASSETS],
    assetsWithBalance: Object.values(assetsBalances).sort((a, b) => a.id - b.id), // *: Ensure that OTF assets are at the end of the array
    areAssetsWithBalanceLoading: isEthBalanceLoading || areErc20TokensLoading,
    refetchAssetsWithBalance: async () => {
      await refetchEthBalance()
      await handleERC20TokensBalance()
    },
  }
}
