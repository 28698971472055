import { readContract } from '@wagmi/core'
import React from 'react'
import { CHAINS } from 'constants/network'
import { env } from 'env.client'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'

type FeatureFlagsContextProps = {
  isDepositLimitEnabled: boolean
  isOTFEnabled: boolean
}

const FeatureFlagsContext = React.createContext<FeatureFlagsContextProps>({
  isDepositLimitEnabled: env.NEXT_PUBLIC_NETWORK !== 'testnet',
  isOTFEnabled: env.NEXT_PUBLIC_NETWORK === 'mainnet',
})

const useFeatureFlagsContext = () => React.useContext(FeatureFlagsContext)

type FeatureFlagsProviderProps = {
  children: React.ReactNode
}

const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const [isDepositLimitEnabled, setIsDepositLimitEnabled] = React.useState<boolean>(
    env.NEXT_PUBLIC_NETWORK !== 'testnet',
  )
  const [isOTFEnabled] = React.useState<boolean>(env.NEXT_PUBLIC_NETWORK === 'mainnet')
  // This is always present as we get this value for current environment and not for custom token bridging.
  const { OptimismPortal, OptimismPortalProxy } = getSmartContracts()

  React.useEffect(() => {
    // *: Can't reuse useDepositLimits hook due to dependency cycle
    const handleIsDepositLimitEnabled = async () => {
      const data = await readContract(wagmiConfig, {
        address: OptimismPortalProxy!.address,
        abi: OptimismPortal!.abi,
        functionName: 'ethThrottleDeposits',
        chainId: CHAINS.l1.id,
      })

      // *: Throttling is disabled on purpose when bridge limit is set to 0
      setIsDepositLimitEnabled(data[2] > 0n)
    }

    void handleIsDepositLimitEnabled()
  }, [OptimismPortal!.abi, OptimismPortalProxy!.address])

  const contextValues = React.useMemo(
    () => ({
      isDepositLimitEnabled,
      isOTFEnabled,
    }),
    [isDepositLimitEnabled, isOTFEnabled],
  )

  return (
    <FeatureFlagsContext.Provider value={contextValues}>{children}</FeatureFlagsContext.Provider>
  )
}

export { FeatureFlagsProvider, useFeatureFlagsContext }
