export type OTF = {
  symbol: string
  name: string
  bridge: 'stargate' | 'hyperlane' | 'free' | 'stakestone'
  contract_address_l1: string
  contract_address_l2: string
  decimals_l1: number
  decimals_l2: number
}

export const OTFS: OTF[] = [
  {
    symbol: 'rsETH',
    name: 'Kelp DAO Restaked ETH',
    bridge: 'stargate',
    contract_address_l1: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
    contract_address_l2: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'rswETH',
    name: 'Restaked Swell ETH',
    bridge: 'stargate',
    contract_address_l1: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
    contract_address_l2: '0xD8b29106d4ceBad087C30B10c0E41BAa3A9ea703',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'swETH',
    name: 'Swell Ethereum',
    bridge: 'stargate',
    contract_address_l1: '0xf951E335afb289353dc249e82926178EaC7DEd78',
    contract_address_l2: '0x850CDF416668210ED0c36bfFF5d21921C7adA3b8',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'STONE',
    name: 'StakeStone ETH',
    bridge: 'stakestone',
    contract_address_l1: '0x7122985656e38BDC0302Db86685bb972b145bD3C',
    contract_address_l2: '0x80137510979822322193FC997d400D5A6C747bf7',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'USDe',
    name: 'Ethena USDe',
    bridge: 'stargate',
    contract_address_l1: '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3',
    contract_address_l2: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'ezETH',
    name: 'Renzo Restaked ETH',
    bridge: 'hyperlane',
    contract_address_l1: '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110',
    contract_address_l2: '0x2416092f143378750bb29b79eD961ab195CcEea5',
    decimals_l1: 18,
    decimals_l2: 18,
  },
  {
    symbol: 'pumpBTC',
    name: 'pumpBTC',
    bridge: 'free',
    contract_address_l1: '0xF469fBD2abcd6B9de8E169d128226C0Fc90a012e',
    contract_address_l2: '0xF469fBD2abcd6B9de8E169d128226C0Fc90a012e',
    decimals_l1: 8,
    decimals_l2: 8,
  },
]
