import React from 'react'
import { RiCheckFill, RiCloseFill, RiLoopRightLine, RiTimerLine } from 'react-icons/ri'
import type { TransactionListItemTxType as TxType } from 'generated/reactQueryClient'
import {
  TransactionListItemExitStatus as TxExitStatus,
  TransactionListItemStatus as TxStatus,
} from 'generated/reactQueryClient'

export const ERROR_CODES = {
  tooManyRequests: 429,
}

export const ERROR_MESSAGES = {
  tooManyRequests: 'Too many requests. You have reached our rate limit. Try again in one minute.',
}

export const PAGE_LENGTH = 10
export const FIRST_PAGE = 1
export const DEFAULT_VALUE = '0'

export const TXN_STATUS_VARIANTS = {
  [TxStatus.successful]: 'success',
  [TxStatus.failed]: 'failed',
  [TxStatus.pending]: 'critical',
  [TxStatus.replaced]: 'information',
} as const

export const TXN_STATUS_ICONS = {
  [TxStatus.successful]: <RiCheckFill size={16} />,
  [TxStatus.failed]: <RiCloseFill size={16} />,
  [TxStatus.pending]: <RiTimerLine size={16} />,
  [TxStatus.replaced]: <RiLoopRightLine size={16} />,
} as const

export const EXIT_TXN_STATUS_LABELS = {
  [TxExitStatus.waiting]: 'Waiting',
  [TxExitStatus.relayed]: 'Relayed',
  [TxExitStatus.ready_to_relay]: 'Ready to finalize',
  [TxExitStatus.in_challenge_period]: 'In finalization period',
  [TxExitStatus.ready_to_prove]: 'Ready to prove',
}

export const TRANSACTION_TYPES = {
  l1l2: 'l1l2',
  l2l1: 'l2l1',
} as const

export const TRANSACTION_TYPE_LABELS = {
  [TRANSACTION_TYPES.l1l2]: 'L1 → L2',
  [TRANSACTION_TYPES.l2l1]: 'L2 → L1',
}

export const EXIT_TRANSACTION_TYPE = 'l2l1' as keyof typeof TxType

export const COLOR_MODE = {
  LIGHT: 'light',
  DARK: 'dark',
} as const

export const NOT_APPLICABLE = 'N/A' as const

export const DECIMALS = 18

export const WEB3_RESPONSE_ERROR_PREFIX = 'execution reverted: ' as const

export const NON_STANDARD_BRIDGE_TOKENS = {
  wstETH: 'wstETH',
}

export const WEB3_USER_REJECT_TRANSACTION_CODE = 4001
